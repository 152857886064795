<template>
  <div class="stats">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">{{ department.title }}</div>
      </div>
      <div class="wrap-subtitle">
        <div class="back" @click="$router.push({name: 'statsOrganizations', params: {id: branchId}})">
          <n-icon icon="arrow-left" />
        </div>
        <div class="title">Сотрудники</div>
      </div>
      <div class="user-list">
        <div class="row header">
          <div class="left text">ФИО</div>
          <div class="right text">Должность</div>
        </div>
      </div>
    </div>
    <div class="user-list out-container">
      <div v-for="item in users" :key="item.id" class="row user">
        <div class="left text">{{ item.fullName }}</div>
        <div class="right text">{{ item.position }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageStats',
  data() {
    return {
      user: $app.auth.user(),
      users: [],
      department: {},
    }
  },
  computed: {
    branchId() {
      return this.$route.params.id
    },
    organizationId() {
      return this.$route.params.organizationId
    },
    departmentId() {
      return this.$route.params.depId
    },
  },
  created() {
    this.loadOrganization()
  },
  methods: {
    loadOrganization() {
      this.$var('load', true)
      $api.company.getOrganizations(this.user.companyId, this.organizationId).then((response) => {
        this.department = response.data.content
        this.loadStats()
      }).catch(() => {
        this.$var('load', false)
      })
    },
    loadStats() {
      $api.company.getActivePeople(this.user.companyId, {
        groupBy: 'organization',
        id: Number(this.branchId),
        departmentId: Number(this.departmentId),
        organizationId: Number(this.organizationId),
      }).then((response) => {
        this.users = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.stats {
  display: flex;
  flex-direction: column;
  padding-top: 29px;
  .wrap-title{
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc( env(safe-area-inset-top) + 100px);
  }
  .wrap-subtitle {
    margin-top: 20px;
    position: relative;
    .back {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;

      ::v-deep {
        svg {
          path {
            fill: #000000;
          }
        }
      }
    }
  }
  .title {
    color: black;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .user-list {
    margin-top: 30px;
    &.out-container {
      background-color: #BDBDBD1A;
      margin-top: 0;
      padding: 20px 30px;
    }
    .text {
      font-weight: 500;
      font-size: 12px;
      line-height: 14.4px;
      color: #000000;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.header {
        margin-bottom: 12px;
      }
      &.user {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }

    }
    .header {
      .text {
        color: #777676;
      }
    }
  }

}
</style>
